import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Count Monterone', voice: 'Baritone', desc: 'A count in the Duke\'s court. His daughter is seduced by the Duke (before the opera starts) leading to him delivering the curse that drives the opera.'},
  {name: 'Duke of Mantua', voice: 'Tenor', desc: 'A totally despicable ruler who sleeps with married women left, right and centre. Very charming but a challenge to like.'},
  {name: 'Gilda', voice: 'Soprano', desc: 'Rigoletto’s daughter who he has fiercely protected from the outside world.'},
  {name: 'Giovanna', voice: 'Mezzo-soprano', desc: 'The nursemaid of Gilda.'},
  {name: 'Maddalena', voice: 'Contralto', desc: ' Sparafucile’s sister. Used as bait by her brother.'},
  {name: 'Rigoletto', voice: 'Baritone', desc: 'The anti-hero of the piece, the Duke’s deformed Jester.'},
  {name: 'Sparafucile', voice: 'Bass', desc: 'A ruthless assassin, albeit one with some sense of honour.'},
];

const inbrief = {name: 'Rigoletto', composer: "Giuseppe Verdi", librettist: "Francesco Maria Piave", language: "Italian", date: 'March 11th, 1851', acts: "Three", musiclength: "Two hours, ten minutes"};

const bibliography = [
  {name: 'Rigoletto: A guide to the opera', writer: 'Charles Osborne', url: 'https://amzn.to/3Dj9C0N'},
  {name: 'Giuseppe Verdi\'s RIGOLETTO COMPLETE LIBRETTO: Rigoletto - Opera Journeys', writer: 'Burton D. Fisher', url: 'https://amzn.to/3Bj4fg5'},
  {name: 'Le Roi s\'amuse', writer: 'Victor Hugo', url: 'https://amzn.to/3kAD4qB'},
];

// markup
const RigolettoPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rigoletto | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/rigoletto" />
          <meta name="description" content="A guide to Giuseppe Verdi's thrilling opera, Rigoletto including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>

        <Hero title="Rigoletto" subtitle="An Opera by Giuseppe Verdi"/>
        <MainBodySection>

          <p>One of the most exciting and gritty operas, <em>Rigoletto</em> is not for the faint-hearted. The censors had real issues with <em>Rigoletto</em>’s content, and it’s not hard to see why! Set in the grubby world of the Duke of Mantua, a man with few morals and a great deal of power, the action plumbs the depths of nastiness, and the bad guy doesn't get a comeuppance. There’s no avoiding the opera’s grim finale either, horrifically sad with only limited redemption.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/rigoletto/ROH_Banner.jpg" alt="Royal Opera Rigoletto"/>
            <figcaption>Rigoletto at the Royal Opera House</figcaption>
          </ImageFull>

          <p>Rigoletto is the first of Verdi’s middle period operas and marks a shift towards coherent musical tapestries instead of the typical Italian aria format (though Verdi by no means leaves the form behind). There are fewer applause breaks than in his earlier works, the drama seemingly rushing headlong towards its conclusion.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>It's an opera with extremes but also a sense of moral ambiguity. The grim palace of the Duke is contrasted by the tranquillity and isolation of Gilda, Rigoletto’s daughter. Gilda gets some of the most divine music, particularly in the Act I aria <em>Caro nome</em>, in English <em>Sweet name</em>. Verdi also cleverly gives the despicable Duke some devilishly charming music, you’ll probably recognise <em>La donna è mobile</em>, <em>Woman is flighty</em>. Rigoletto himself gets no flashy arias; his music is punchier and more declamatory, an uneasy mix of both worlds.</p>

          <VideoPlayer src="https://www.youtube.com/embed/hT1bLf_0Ilc?autoplay=1" id="hT1bLf_0Ilc" title="Diana Damrau as Gilda sings 'Caro Nome'"/>

          <p>With a great balance between the grand and the intimate, from plush palace to rundown inn, <em>Rigoletto</em> is an opera that has it all.</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 65 mins</SynopsisAct>

            <p>A palace ball is in full flow. The Duke of Mantua sings of his lascivious life. Amongst his many potential future conquests, he is eyeing up an unknown girl he caught sight of in church. Rigoletto mocks all and sundry, a court jester who does this job in a nasty, deeply unpopular manner. The various nobles gossip about Rigoletto’s “lover”, most don’t believe she exists, but they resolve somehow to have their revenge on him.</p>

            <p>Count Monterone enters angry, decrying that the Duke has had his way with Monterone’s daughter. Rigoletto ridicules him, and the Duke orders him arrested. As he is dragged out Monterone calls down a curse on Rigoletto and the Duke.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/Curse_MetOpera_BethBergman.jpg" alt="Rigoletto is cursed, Metropolitan Opera"/>
              <figcaption>Rigoletto is cursed, Metropolitan Opera</figcaption>
            </ImageFull>

            <p>Rigoletto trudges home, worried by the curse. He comes across Sparafucile, the local assassin (every town should have one!), skulking in the darkness. Sparafucile offers his services, but Rigoletto rejects his offer, saying he does not need them.</p>

            <p>Rigoletto enters his home, where his daughter, Gilda, greets him. She is the figure that the noblemen suspect to be his lover. Rigoletto has kept Gilda secret from the Duke, and Gilda does not know about Rigoletto’s job at the palace.</p>

            <p>The only place Gilda is permitted to go is the church, and she has recently fallen in love with a young man she has seen there.</p>

            <p>As soon as Rigoletto leaves, that man promptly appears, and would you believe it... it’s the Duke! He bribes Giovanna into letting him in and in the ensuing love song lies to Gilda, telling her that he is a poor student.</p>

            <p>He departs. Outside the house, the noblemen mass to abduct Gilda, who they still think is Rigoletto’s lover. Somehow in the darkness, they trick Rigoletto into believing they are kidnapping a woman from the house adjacent to his, and in the ensuing action, Rigoletto actually assists in the kidnapping of his daughter.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/GildaAbduct_CoryWeaver_SFO.jpg" alt="The noblemen gather outside Rigoletto's house, San Francisco Opera"/>
              <figcaption>The noblemen gather outside Rigoletto's house, San Francisco Opera</figcaption>
            </ImageFull>

            <p>He realises what has happened and is utterly distraught. He recalls the curse as the curtain descends.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 35 mins</SynopsisAct>

            <p>We’re back in the palace. The Duke is missing his Gilda, but he is soon excited to hear that the noblemen have just kidnapped her. She is in his bedroom, which is like Christmas coming early for the Duke. He dashes off as Rigoletto enters, trying to appear like nothing is wrong.</p>

            <p>He tries to find out what has happened to Gilda without giving much away but soon breaks down and desperately demands her return. Predictably he doesn’t have much luck, and the noblemen forcibly prevent him from entering the Duke’s chambers.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/Rigoletto_Stopped_SemperOper_MC.jpg" alt="Rigoletto (Zeljko Lucic) demands to see his daughter"/>
              <figcaption>Rigoletto (Zeljko Lucic) demands to see his daughter &copy; Matthias Creutziger/Semperoper Dresden</figcaption>
            </ImageFull>

            <p>Gilda emerges dishevelled. As she describes what has happened to her, Rigoletto gets very angry indeed. Monterone happens to be being dragged through and curses the fact that his curse has not come to pass. Rigoletto shouts after him that he is wrong and swears vengeance on the Duke, though Gilda continues to defend the Duke as she is still in love...</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 35 mins</SynopsisAct>

            <p>We find ourselves at the home of Sparafucile (the assassin from Act I). While we have been supping interval drinks, Rigoletto has arranged for Sparafucile to kill the Duke and present the body in a sack for Rigoletto to dispose of himself in the river.</p>

            <p>Sparafucile has used his sister, Maddalena, to lure the Duke to his home. At the same time, Rigoletto has brought Gilda to the house to convince her of the Duke’s infidelity. The Duke sings the famous aria, “La donna e mobile”, and despite overhearing everything, Gilda remains in love, and Rigoletto sends her away to prepare (by dressing as a boy!) for their escape to Verona.</p>

            <VideoPlayer src="https://www.youtube.com/embed/6HcrVzwlocA?autoplay=1" id="6HcrVzwlocA" title="Placido Domingo sings 'La Donna e Mobile'"/>
            
            <p>The Duke goes to bed. Gilda comes creeping back to overhear Maddalena, trying to convince Sparafucile not to kill the Duke. Sparafucile agrees to kill anyone who comes to his door before midnight. If no one comes, the Duke will die. Gilda, still deeply in love, resolves to sacrifice herself. She knocks on the door and Sparafucile stabs her.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/Tavern_SemperOper_MatthiasCreutziger.jpg" alt="Gilda overhears"/>
              <figcaption>Gilda overheads &copy; Matthias Creutziger/Semperoper Dresden</figcaption>
            </ImageFull>

            <p>Rigoletto returns and is given the sack by Sparafucile. He gloats over what he believes is the body of the Duke. The Duke, still safely alive, is overheard singing. In a panic, Rigoletto cuts open the sack to find his beloved daughter inside! With her dying breath, Gilda begs for forgiveness. Monterone’s curse has come to pass!</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/rigoletto_finale_ken_howard_MET.jpg" alt="Rigoletto (Zeljko Lucic) mourns the death of Gilda (Diana Damrau)"/>
              <figcaption>Rigoletto (Zeljko Lucic) mourns the death of Gilda (Diana Damrau) &copy; Ken Howard/Met Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <p>Commissioned in April 1850 by La Fenice in Venice, Verdi set about finding a suitable subject for his new opera. He chose as his Librettist Francesco Marie Piave with whom he had worked on five previous operas, and after an intense search, Verdi decided upon Victor Hugo’s Le Roi s’amuse, a subject that was always likely to cause problems with the censors (the play itself had a checkered history with censorship).</p>

            <p>Sure enough, Verdi was asked to submit the libretto to the Austrian censors (Venice, a constituent state of Austria at the time), who took exception to the portrayal of an evil monarch and, to compound this, a bad character whose evildoing is not punished at the opera’s conclusion.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/Italia_1843.jpg" alt="Italy in 1843, a divided nation with Austria controlling the north"/>
              <figcaption>Italy in 1843, a divided nation with Austria controlling the north</figcaption>
            </ImageFull>

            <p>Verdi had no intention of making substantial changes, and for a time, it looked like Rigoletto, or “La Maledizione” (The Curse) as it then was, would never reach the stage. This wasn’t good for anyone, including La Fenice, who, having paid Verdi, needed an opera. As luck would have it, one of the Austrian censors, Luigi Martello, was a Verdi fan, and it was he who suggested shifting the action from the French king to a fictional Mantuan Duke. It was at this stage that <i>Rigoletto</i> gained its name with the hunchback jester Triboulet becoming Rigoletto, a name taken from the title of another Hugo play, <i>Rigoletti, ou Le dernier des fous</i>. Rigolo means funny in French!</p>

            <p>Verdi was sufficiently happy with the results that he set to work, writing the bulk of the score in 40 days. The premiere was a huge success, and Rigoletto has never been far from the operatic stage since.</p>
            
            <ImageFull>
              <StaticImage src="../../images/operas/rigoletto/Rigo_Costumes.jpg" alt="Costume designs for the original production in Venice 1851"/>
              <figcaption>Costume designs for the original production in Venice 1851</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Le Roi s'amuse</FunFactsHeader>

            <p>Victor Hugo’s Le Roi s’amuse, premiered in November 1832, was on the surface simply historical fiction about Francis I. He was the king of France for most of the first half of the 16th Century, now long dead. However, the French government understood the play (quite accurately) as little more than a thinly veiled attack on the current King: Louis Philippe.</p>
            
            <p>Louis had come to the throne only two years earlier, and after many years of political unrest, the government was extremely twitchy about perceived criticism. The play was swiftly banned. This particularly offended Hugo as censorship had supposedly been eliminated only two years previous.</p>
            
            <p>In the end, the censorship didn’t work. The printed text became wildly popular. By the time Verdi would come to write Rigoletto, Louis had been deposed and died though Hugo was in no better state, having been exiled from France by Napoleon III.</p>

            <FunFactsHeader>Which Duke?</FunFactsHeader>

            <p>Though Rigoletto was originally based on French kings, Verdi was not without a historical model for dirty Mantuan Dukes. There are a few possible candidates, almost all of whom belong to the Gonzaga family.</p>
            
            <p>Most likely is Vincenzo Gonzaga, who was Duke of Mantua from 1562-1612. His father was a frugal, puritanical hunchback, but Vincenzo took a different path, spending vast money on wine, horses and music. His patronage of Claudio Monteverdi should endear him to any opera lover, but his handling of the Mantuan court make comparisons with Rigoletto’s Duke not unfair.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>La Donne e Mobile</FunFactsHeader>
            
            <p>Verdi was convinced he had written a modern, dramatic work (as we’d probably now all agree). He was not at all impressed when the husband of Teresa De Giuli-Borsi, who was to sing Gilda, asked for an extra aria to be inserted for her. Verdi proclaimed, “My intention was that Rigoletto should be one long series of duets, without arias and finales”.</p>

            <p>That said, he wasn’t blind to how Rigoletto might well be received and was acutely aware that he had written something of a humdinger in the form of “La Donne e Mobile”. The legend goes that to avoid the tune becoming common property to all of Venice, he swore his singers to secrecy and wouldn’t provide the music for the song until mere days before the first performance.</p>

            <FunFactsHeader>Francesco Piave</FunFactsHeader>

            <ImageRight maxwidth="95px">
              <StaticImage src="../../images/operas/rigoletto/Piave.jpg" alt="Francesco Piave"/>
              <figcaption>Francesco Piave</figcaption>
            </ImageRight>

            <p>Librettists have never really gotten their due in the operatic world but Francesco Maria Piave was a significant part of Verdi’s career. He wrote 10 libretti for Verdi starting with <i>Ernani</i> and including many of his greatest works including <i>La Traviata</i> and <i>Simon Boccanegra</i>.</p>
            
            <p> Among his many skills were his abilities as a negotiator, a talent he was repeatedly forced to use with Verdi and the various European censors. Verdi could be harsh with him; he gave him hell when the first version of <i>Rigoletto</i> was blocked, but they formed a strong bond over many years, and after Piave suffered a stroke in 1867, Verdi supported his wife and daughter as well as paying for his funeral when he died in 1876.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Popularity</FunFactsHeader>

            <p><i>Rigoletto</i> was astonishingly popular from its premiere in 1851, reaching London by 1853 (The English libretto sold at Covent Garden on the right) and New York by 1855. Censorship continued to be a problem, albeit a minor one, especially in France where Hugo was immensely displeased that his original play was still banned whilst Verdi’s work could, and regularly was, performed. Popularity-wise not much has changed over the intervening 150 years, and <i>Rigoletto</i> was the 9th most performed opera in the world from 2007/2008 to 2011/2012. </p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default RigolettoPage
